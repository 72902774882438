import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material";
import Navigation from "./Navigation";
import { colors } from "./assets/colors";

function App() {
  const theme = createTheme({
    //logo is EB Garamond
    palette: {
      
      primary: { main: colors.primary },
      info: {main: `${colors.primary}80`}
    },
  
    typography: {
      button: {
        textTransform: "none",
        fontFamily: "Staatliches"
      },
      
      fontFamily: ["Roboto", "Staatliches", ].join(","),
      h1: {
        fontSize: "3em",
        fontFamily: "Roboto",
        fontWeight: 600,
        color: 'white'
      },
      h2: {
        fontSize: "2.6em",
        fontFamily: "Roboto",
        color: 'white',
        fontWeight: 600,
      },
      h3: {
        fontSize: "2.3em",
        fontFamily: "Roboto",
        fontWeight: 500,
        color: "white"
      },
      h4: {
        fontSize: "2em",
        fontFamily: "Roboto",
        color: 'white'
      },
      h5: {
        fontSize: "1.8em",
        fontFamily: "Roboto",
      },
      h6: {
        fontSize: "1.5em",
        fontFamily: "Roboto",
      },
      subtitle1: {
        fontSize: "1.2em",
        fontFamily: "Roboto",
      },
      p: {
        fontSize: "1em",
        color: 'white',
        fontFamily: "Roboto",
        margin: 0,
      },
      
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App background-gradient">
      <style>
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
</style>

        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
        </style>
        <Navigation />
      </div>
    </ThemeProvider>
  );
}

export default App;
