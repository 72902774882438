import { MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "./Logo";

const Footer = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: '5em',
        marginTop: 'auto',
      }}
    >
      <Logo textSize={"2em"} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        
      </div>
      <span
        style={{
          color: "white",
          fontWeight: 200,
          fontSize: 13,
          marginBottom: 30,
        }}
      >
        @ 2023 Draughn & Alink, LLP. All Rights Reserved.
      </span>
    </div>
  );
};

export default Footer;
