import { Button, Modal, Typography } from "@mui/material";
import { GiArrowCursor } from "react-icons/gi";
import { colors } from "../../assets/colors";

const DocScreen = () => {
  return (
    <div
      className="doc-container"
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        backgroundColor: "grey",
      }}
    >
      <div
        style={{
          width: "calc(35% - .4em)",
          backgroundColor: "white",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          padding: ".3em .2em",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="p"
          sx={{ mb: ".5em", fontWeight: 500, alignSelf: "center", color: 'black' }}
          fontSize={".4em"}
        
        >
          My Website
        </Typography>

        <div
          className="skeleton one"
          style={{ fontSize: ".3em", width: "40%" }}
        />
        <div
          className="skeleton two"
          style={{ fontSize: ".3em", width: "70%" }}
        />
        <div
          className="skeleton three"
          style={{ fontSize: ".3em", width: "40%", marginTop: ".7em" }}
        />
        <div
          className="skeleton four"
          style={{ fontSize: ".3em", width: "90%" }}
        />
        <div
          className="skeleton five"
          style={{ fontSize: ".3em", width: "20%" }}
        />
        <div
          style={{ display: "flex", alignItems: "center", marginTop: ".3em" }}
        >
          <div
            className="skeleton six"
            style={{ width: ".5em", height: ".5em" }}
          />
          <div
            className="skeleton seven"
            style={{
              fontSize: ".5em",
              width: "4em",
              marginLeft: ".2em",
              borderRadius: ".2em",
            }}
          />
        </div>

        <div
          className="skeleton eight"
          style={{
            width: "40%",
            height: "20%",
            marginTop: ".3em",
            borderRadius: ".2em",
          }}
        />
      </div>
      <div className="share-modal-container">
        <div className="share-modal">
          <div className="skeleton" style={{ height: ".3em", width: "15%" }} />
          <div className="skeleton" style={{ height: ".3em", width: "30%" }} />

          <div
            className="skeleton nine"
            style={{ fontSize: ".3em", width: "70%", marginTop: ".7em" }}
          />
          <div
            className="skeleton ten"
            style={{ fontSize: ".3em", width: "20%" }}
          />
          <Button className = "share-button" sx={{ position: "absolute", right: 0, bottom: 0 }}>
            Share
          </Button>
        </div>
      </div>
      <GiArrowCursor
        color={colors.primary}
        className="cursor-doc-screen"
        style={{ fontSize: ".3em" }}
      />
    </div>
  );
};

export default DocScreen;
