import { ChevronRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

import "./PortfolioModule.css";
const PortfolioModule = ({
  title,
  subtitle,
  image,
  setActiveBackground,
  link,
}) => {
  return (
    <Box
      className="portfolio-module-container"
      onClick={() => {
        link
          ? window.open(link, "_blank", "noreferrer")
          : alert("Coming soon!");
      }}
      onMouseEnter={() => setActiveBackground(image)}
    >
      <div className="portfolio-gradient" />
      <div>
        <Typography variant="h1" sx={{ marginBottom: ".5em" }}>
          {title}{!link && <Button sx= {{marginLeft: '3em', fontSize: '.5em'}} variant = "contained">Coming Soon</Button>}
        </Typography>
        <Typography variant="p">{subtitle}</Typography>
      </div>
      <ChevronRight
        className="arrow-icon"
        sx={{ marginLeft: "auto", fontSize: "4.5em", color: "white" }}
      />
    </Box>
  );
};

export default PortfolioModule;
