import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Portfolio from "./components/portfolio/Portfolio";
import Landing from "./landing/Landing";

const Navigation = () => {
  return (
    <Router>
      <div>
      <Header />
        <Routes>
          <Route path="/" element={<Landing></Landing>} />
          <Route path="/home" element={<Navigate to='/'></Navigate>} />
          <Route path="/" element={<Landing></Landing>} />
          <Route path="/portfolio" element={<Portfolio/>} />
          
          <Route path="/contact" element={<Portfolio/>} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
};

export default Navigation;
