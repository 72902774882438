import { useEffect } from "react";
import CardModule from "./CardModule";
import DevicesIcon from "@mui/icons-material/Devices";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Paid } from "@mui/icons-material";

const SelectionCards = () => {
  useEffect(() => {
    document.getElementById("cards").onmousemove = (e) => {
      for (const card of document.getElementsByClassName("card")) {
        const rect = card.getBoundingClientRect(),
          x = e.clientX - rect.left,
          y = e.clientY - rect.top;

        card.style.setProperty("--mouse-x", `${x}px`);
        card.style.setProperty("--mouse-y", `${y}px`);
      }
    };
  }, []);

  return (
    <div id="cards">
      <CardModule
        title={"Seamless Design"}
        subtitle={"Elevate your digital presence"}
        icon={<DevicesIcon style={{ fontSize: "5em" }} />}
      />
      <CardModule
        title={"Collaborative Partnership"}
        icon={<HandshakeIcon style={{ fontSize: "5em" }} />}
        subtitle={"Our expertise, your vision"}
      />
      <CardModule
        title={"Fuel Your Growth"}
        icon={<><Paid style={{ fontSize: "5em" }} /></>}
        subtitle={"Maximize return, minimize cost"}
      />
      <CardModule
        title={"Quick Turnaround"}
        icon={<RocketLaunchIcon style={{ fontSize: "5em" }} />}
        subtitle={"Rapidly scale your business"}
      />
    </div>
  );
};

export default SelectionCards;
