import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Close } from "@mui/icons-material";

const Contact = ({ moonClass, hideMoon }) => {

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    await emailjs
      .send(
        "service_q5jnjf9",
        "template_hiqke3s",
        {
          name: data.get("name"),
          email: data.get("email"),
          subject: data.get("subject"),
          message: data.get("message"),
        },
        "lkXy20z68OpeLFbkW"
      )
      .then(() => hideMoon());

    e.currentTarget.reset();
  };

  const CssTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {},
  });

  return (
    <div className={`responsive-width moon ${moonClass}`}>
      <div
        className={`contact-form ${
          moonClass == "moon-active"
            ? "contact-form-active"
            : moonClass == "moon-inactive"
            ? "contact-form-inactive"
            : ""
        }`}
            >
        <IconButton sx = {{ position: 'absolute', top: "8em", right: '8em' }} onClick={() => hideMoon()}>
        <Close sx ={{fontSize: '5em',}}/>
        </IconButton>
        <Typography variant="h1" sx={{ mb: "1em" }} color="primary">
          Contact Us
        </Typography>
        <FormControl
          style={{ gap: "1em", width: "80%" }}
          onSubmit={handleSubmit}
          variant="filled"
          component="form"
        >
          <TextField
            id="name"
            name="name"
            label="Name"
            variant="outlined"
            required
            color="primary"
           
          />
          <TextField
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            required
            // InputLabelProps={{ style: { color: "white" } }}
            // InputProps={{ style: { color: "white" } }}
          />
          <TextField id="subject" label="Subject" variant="outlined" required />
          <TextField
            inputProps={{ borderColor: "white" }}
            id="message"
            name="message"
            label="Message"
            multiline
            variant="outlined"
            required
          />
          <Button
            type="submit"
            size="large"
            style={{
              padding: "10px 100px",
              alignSelf: "center",
              marginBottom: "4%",
            }}
            variant="contained"
           
          >
            Send
          </Button>
          
        </FormControl>
      </div>
    </div>
  );
};

export default Contact;
