import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import './Logo.css'
const Logo = ({textSize}) => {
  const navigate = useNavigate();
  return (
    <Typography
    className = "logo"
      variant="h1"
      style={{ fontSize: textSize || "2.8rem", fontFamily: "Staatliches" }}
      component="div"
      onClick={() => navigate('/')}
    >
      Draughn & Alink
    </Typography>
  );
};

export default Logo;
