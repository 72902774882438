import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./Monitor.css";

const Monitor = ({ children, style, sx }) => {
  const windowSize = useWindowDimensions();
  return (
    <div
      className="monitor"
      style={{
        fontSize: windowSize.width > 1000 ? `calc(2.5em * ${windowSize.width / 1920})` : `calc(2.5em * ${windowSize.width/500})`,
        display: "flex",
        flexDirection: "column",
        ...sx,
        ...style,
      }}
    >
      <div
        style={{
          backgroundColor: "black",
          height: "8em",
          width: "16em",
          alignSelf: "center",
          border: ".3em black solid",
          borderRadius: ".3em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
      <div
        style={{
          backgroundColor: "black",
          height: "1.5em",
          width: "1.5em",
          alignSelf: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "black",
          height: ".5em",
          width: "6em",
          alignSelf: "center",
          borderTopRightRadius: ".3em",
          borderTopLeftRadius: ".3em",
        }}
      ></div>
    </div>
  );
};

export default Monitor;
