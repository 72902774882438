import { IconButton } from "@mui/material";

const CardModule = ({ style, sx, title, subtitle, icon }) => {
  return (
    <div class="card" style={{ ...style, ...sx }}>
      <div class="card-content">
        <div class="card-image">
          <IconButton color="primary" style = {{opacity: '.7'}}>{icon}</IconButton>
        </div>
        <div class="card-info-wrapper">
          <div class="card-info">
            <div class="card-info-title">
              <h3 style={{color: 'white'}}>{title}</h3>
              <h4>{subtitle}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardModule;
