import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { MenuSharp } from "@mui/icons-material";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 0,
    style: {
      ...children.style,
      backgroundColor: trigger ? `#00000080` : "transparent",
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function DummyHeader(props) {
  const [navAnchor, setNavAnchor] = React.useState();

  const pages = ["Home", "Pricing", "Portfolio"];

  return (
    <React.Fragment>
      <Toolbar
        style={{
          display: "flex",
          alignItems: "flex-start",
          padding: "2.2em 5em",
        }}
      >
        <Typography
          variant="h1"
          style={{ fontSize: "2.8em", fontFamily: "Staatliches" }}
          component="div"
        >
          Draughn & Alink
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {pages.map((page) => (
            <Button
              key={page}
              sx={{
                display: "block",
                fontFamily: "Roboto",
                padding: 0,
                margin: 0,
              }}
            >
              <h3 style={{ fontSize: ".5em", padding: 0, margin: 0 }}>
                {page}
              </h3>
            </Button>
          ))}
        </Box>
      </Toolbar>
    </React.Fragment>
  );
}
