import { Box, Typography } from "@mui/material";
import Header from "../Header";
import TypingAnimation from "../TypingAnimation";
import DummyHeader from "./DummyHeader";
import Monitor from "./Monitor";
import { useEffect, useState } from "react";
import { GiArrowCursor } from "react-icons/gi";
import { colors } from "../../assets/colors";

const LandingAnim = () => {
  return (
    <Box
      className="background-gradient screen-slide"
      sx={{
        fontSize: ".1em",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DummyHeader />
      <Box
        sx={{
          position: "relative",
          alignSelf: "center",
          width: "60%",
          height: "12em",
        }}
      >
        <TypingAnimation />
      </Box>
      <GiArrowCursor
        color={colors.primary}
        className="cursor-landing"
        style={{ fontSize: "3em" }}
      />
      <Box style = {{display: 'flex', justifyContent: 'center', marginBottom: '15em', marginTop: '2em', alignItems: 'center'}}>
        <button
          className={`btn request glow-box fake-button`}
          
        >
          <Box className="btn-circle-fake"></Box>
          <Typography sx={{zIndex: 1}} fontSize={".8em"} variant="h3">
            Request a quote
          </Typography>
        </button>
      </Box>
    </Box>
  );
};
export default LandingAnim;
