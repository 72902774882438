import { Typography, Box, Button, Skeleton, Stack, Grid } from "@mui/material";
import "./Landing.css";
import TypingAnimation from "../components/TypingAnimation";
import Monitor from "../components/processanim/Monitor";
import Browser from "../components/processanim/Browser";
import { TravelExplore } from "@mui/icons-material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useEffect } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { colors } from "../assets/colors";
import SelectionCards from "../components/SelectionCards";
import DevicesIcon from '@mui/icons-material/Devices';
import Contact from "../contact/Contact";
import { useState } from "react";
import contactMoon from '../assets/contact-moon.jpg';

//TODO, moon contact anim jumps in first time, then works fine. 
const Landing = () => {
  const windowSize = useWindowDimensions();
  const [moonClass, setMoonClass] = useState('')

  const handleRequestQuote = () => {
    const container = document.getElementById("landing-container");
    container.classList.add('darken')
    setMoonClass('moon-active')
  }

  const hideMoon = () => {
    const container = document.getElementById("landing-container");
    container.classList.remove('darken')
    setMoonClass('moon-inactive')
  }

  //load in moon image on page load
  useEffect(() => {
    const moon = new Image();
    moon.src = contactMoon;
  }, [])

  
  return (
    <div>
    <Box
      className="page-container"
      id='landing-container'
      sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
      <Box
        className="responsive-width"
        sx={{ position: "relative", alignSelf: "center", minHeight: "35dvh" }}
        >
        <TypingAnimation sx={{ position: "absolute" }} />
      </Box>

      <Box style = {{display: 'flex', justifyContent: 'center', marginBottom: '15dvh', marginTop: '2dvh', alignItems: 'center'}}>
        
        <button className="btn request glow-box" onClick={() => {
        handleRequestQuote()
        }}>
        <Box className="btn-circle"></Box>
          <Typography sx={{zIndex: 1, }} fontSize='2.5em' variant="h3">
            Request a quote
          </Typography>
        </button>
      </Box>
      
      <Box
        className="responsive-width eighty"
        sx={{
          display: "flex",
          alignSelf: "center",
          marginTop: "2.5em",
          justifyContent: "space-around",
          flexWrap: "wrap-reverse",
          gap: '5em'
        }}
      >
        <SelectionCards/>
        <Monitor>
          <Browser />
        </Monitor>
      </Box>
    </Box>
    <Contact moonClass={moonClass} hideMoon={hideMoon} />
    </div>
  );
};

export default Landing;
